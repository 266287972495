import React from "react";

export default function FlexRow({
  style = {},
  align = "",
  justifyContent = "",
  nowrap = false,
  ...props
}) {
  return (
    <div
      style={{
        display: "flex",
        flexShrink: 0,
        flexWrap: nowrap ? "nowrap" : "wrap",
        ...style,
        justifyContent,
      }}
      {...props}
    />
  );
}
