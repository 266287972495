export const primary = {
  _900: "#12283A",
  _700: "#1a4971",
  _600: "#2368a2",
  _500: "#3183c8",
  _400: "#63a2d8",
  _300: "#aad4f5",
  _100: "#eff8ff",
};

export const gray1 = {
  _1000: "#102A43",
  _900: "#243B53",
  _800: "#334E68",
  _700: "#486581",
  _600: "#627D98",
  _500: "#829AB1",
  _400: "#9FB3C8",
  _300: "#BCCCDC",
  _200: "#D9E2EC",
  _100: "#F0F4F8",
};

export const gray2 = {
  _1000: "#27241D",
  _900: "#423D33",
  _800: "#504A40",
  _700: "#625D52",
  _600: "#857F72",
  _500: "#A39E93 ",
  _400: "#B8B2A7",
  _300: "#D3CEC4",
  _200: "#E8E6E1",
  _100: "#FAF9F7",
};

export const gray = {
  _1000: "#1F2933",
  _900: "#323F4B",
  _800: "#3E4C59",
  _700: "#52606D",
  _600: "#616E7C",
  _500: "#7B8794",
  _400: "#9AA5B1",
  _300: "#CBD2D9",
  _200: "#E4E7EB",
  _100: "#F5F7FA",
};

export const success = {
  _900: "#155239",
  _700: "#187741",
  _600: "#249D57",
  _500: "#38C172",
  _400: "#74D99F",
  _300: "#A8EEC1",
  _100: "#E3FCEC",
};

export const danger1 = {
  _900: "#761718",
  _700: "#881A1B",
  _600: "#B82020",
  _500: "#DC3030",
  _400: "#E36363",
  _300: "#F4AAAA",
  _100: "#FCE8E8",
};

export const danger2 = {
  _900: "#610316",
  _800: "#8A041A",
  _700: "#AB091E",
  _600: "#CF1124",
  _500: "#E12D39",
  _400: "#EF4E4E",
  _300: "#F86A6A",
  _200: "#FF9B9B",
  _150: "#FFBDBD",
  _100: "#FFE3E3",
};

export const danger = {
  _900: "#610404",
  _800: "#780A0A",
  _700: "#911111",
  _600: "#A61B1B",
  _550: "#BA2525",
  _500: "#D64545",
  _400: "#E66A6A",
  _300: "#F29B9B",
  _200: "#FACDCD",
  _100: "#FFEEEE",
};

export const warning = {
  _900: "#5C4813",
  _700: "#8C6D1F",
  _600: "#CAA53D",
  _500: "#F4CA64",
  _400: "#FAE29F",
  _300: "#FDF3D7",
  _100: "#FFFCF4",
};
export const accent = {
  _900: "#114443",
  _700: "#1B655E",
  _600: "#2A9187",
  _500: "#3CAEA3",
  _400: "#6ED7D3",
  _300: "#A8EEEB",
  _100: "#E7FFFE",
};

export const spendeskColor = {
  darkPuple: "#301c6b",
};
