import React, { useState, useEffect } from "react";
import { FlexColumn, FlexRow, Spacer } from "../../ui";
import * as palet from "../../ui/palet";

import { Divider } from "antd";

import InfiniteScroll from "react-infinite-scroller";

import LoginButton from "./LoginButton";

export default function DisplayUsers({ users }) {
  const roles = ["Account owner", "Administrator", "Controller", "Requester"];
  if (!users) return "no users";
  return (
    <div className="demo-infinite-container users-list">
      <InfiniteScroll initialLoad={false} pageStart={0} useWindow={false}>
        {roles.map((role) => (
          <React.Fragment>
            <Divider orientation="left">
              <b>{role}</b>
            </Divider>
            {users
              .filter((users) => users.role === role)
              .map((user) => (
                <UserComponent user={user} />
              ))}
          </React.Fragment>
        ))}
      </InfiniteScroll>
    </div>
  );
}

function UserComponent({ user }) {
  return (
    <React.Fragment>
      <FlexRow
        style={{
          background: palet.gray._100,
          padding: 20,
          borderRight:
            "3px solid " +
            (user.role === "Account owner"
              ? palet.spendeskColor.darkPuple
              : "transparent"),
          marginBottom: 11,
        }}
      >
        <FlexColumn style={{ flex: 1 }}>
          <FlexRow>
            <span style={{ color: palet.gray._900, flex: 1 }}>
              {user.fullname}
            </span>
            <div>
              <LoginButton user={user} text={"Log as"} />
            </div>
            <Spacer />
          </FlexRow>
          <div style={{ color: palet.gray._600, fontSize: 14 }}>
            <span style={{ width: 80, display: "inline-block" }}>email:</span>
            <b>{user.email}</b>
          </div>
          <div
            style={{
              color: palet.gray._600,
              fontSize: 14,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span
                style={{
                  width: 80,
                  display: "inline-block",
                }}
              >
                password:
              </span>
              <b>{user.password}</b>
            </div>
          </div>
        </FlexColumn>
      </FlexRow>
    </React.Fragment>
  );
}
