import React, { useState, useEffect, useContext } from "react";
import { palet, FlexColumn, Spacer, FlexRow } from "../../ui";
import RenderProcess from "./renderProcess";
import ListProcess from "./ListProcess";
import Form from "./Form/Form";
import { ENDPOINT } from "./config";
import SpendeskLogo from "../../images/spendesk-logo.svg";
import { Callout } from "@dev-spendesk/grapes";
import { AuthenticatedApiContext } from "../context/AuthenticatedApiContext";

const feedbackType = {
  cookie: "alert",
  dataResetFinished: "success",
  fetchData: "alert",
  companyDeleted: "success",
  dataResetClick: "info",
};

export default function SalesPlatform({ userLogged }) {
  const [processId, setProcessId] = useState();
  const [processes, setProcesses] = useState([]);
  const [feedback, setFeedback] = useState({});
  const [isCreateCompanyView, setIsCreateCompanyView] = useState(false);
  const { get, del, post } = useContext(AuthenticatedApiContext);
  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    try {
      const results = await get(`${ENDPOINT}/processes`);
      if (results.length) {
        setProcesses(results);
      }
      setFeedback({});
    } catch (err) {
      console.log(err)
      setFeedback({
        type: "fetchData",
        message: "Couldn't fetch data from server. Contact the support team.",
      });
    }
  }

  async function submit(data) {
    const company = await post(`${ENDPOINT}/create-company`, data);
    setIsCreateCompanyView(false);
    setProcessId(company.process_id);
    await fetch();
  }

  async function deleteProcess(processId) {
    try {
      await del(`${ENDPOINT}/processes/${processId}`);
      setProcessId(processId);
      await fetch();
      setFeedback({
        type: "companyDeleted",
        message: "Your company has been successfully deleted",
      });
      setProcessId(null);
    } catch (err) {
      console.log(err);
      setProcesses(null);
    }
  }

  function handleProcessSelection(processId) {
    setProcessId(processId);
    setFeedback({});
  }

  function handleResetDataFinished() {
    setFeedback({
      type: "dataResetFinished",
      message: "Your account's data has been reseted successfully",
    });
  }
  function handleResetDataClick() {
    setFeedback({
      type: "dataReset",
      message: "Your account's data has been reseted successfully",
    });
  }

  function onPullingSucceed() {
    fetch();
  }

  function onCreateCompany() {
    setIsCreateCompanyView(true);
    setProcessId(null);
    setFeedback({});
  }

  async function onHomePageClick() {
    setIsCreateCompanyView(false);
    setProcessId(null);
    setFeedback({});
    await fetch();
  }

  return (
    <Layout style={{ width: "1149px" }}>
      {feedback.type && (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            width: "50%",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <Callout
            title={feedback.message}
            variant={feedbackType[feedback.type]}
          />
        </div>
      )}

      {processId && (
        <RenderProcess
          isResetDataFinished={handleResetDataFinished}
          onResetDataClick={handleResetDataClick}
          onPullingSucceed={onPullingSucceed}
          processId={processId}
          key={processId}
          isProcessSelected={onHomePageClick}
          deleteProcess={deleteProcess}
        />
      )}

      {!processId && !isCreateCompanyView && (
        <ListProcess
          processes={processes}
          onProcessSelection={handleProcessSelection}
          userEmail={userLogged && userLogged.email}
          onCreateCompanyClick={onCreateCompany}
        />
      )}
      {isCreateCompanyView && (
        <Form
          onSubmit={submit}
          userEmail={userLogged && userLogged.email}
          onHomepageClick={onHomePageClick}
        />
      )}
    </Layout>
  );
}

function Layout({ children }) {
  return (
    <FlexColumn>
      <div
        style={{
          background: palet.spendeskColor.darkPuple,
          textAlign: "center",
          height: 50,
          lineHeight: "50px",
          color: "white",
          position: "fixed",
          right: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10,
        }}
      >
        <img src={SpendeskLogo} style={{ height: "40px" }} alt="" />
      </div>
      <Spacer size="large" />
      <FlexRow>
        <div style={{ margin: "0 auto", flex: 1, marginTop: "40px" }}>
          {children}
        </div>
      </FlexRow>
    </FlexColumn>
  );
}
