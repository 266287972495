import React from "react";

import { Form, Radio } from "antd";

export const ChooseLanguage = () => {
  return (
    <Form.Item
      style={{ width: "49%", padding: "0 5px 0 0" }}
      name="currency"
      label="Language"
      initialValue="fr"
      rules={[{ required: true, message: "Please pick an item!" }]}
      name="config.usersLanguage"
    >
      <Radio.Group style={{ display: "flex" }} buttonStyle="solid">
        <Radio.Button className="radio-currency" value="fr">
          FR
        </Radio.Button>
        <Radio.Button className="radio-currency" value="en">
          EN
        </Radio.Button>
        <Radio.Button className="radio-currency" value="de">
          DE
        </Radio.Button>
        <Radio.Button className="radio-currency" value="es">
          ES
        </Radio.Button>
        <Radio.Button className="radio-currency" value="it">
          IT
        </Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};
