import { Button as SpendeskButton } from "@dev-spendesk/grapes";
import React, { useState } from "react";

import { Button, Form, Typography } from "antd";
import "antd/dist/antd.css";

import { ChooseBillingPlan } from "./BillingPlans";
import { ChooseCurrency } from "./ChooseCurrency";
import { ChooseLanguage } from "./ChooseLanguage";
import { HiddenFields } from "./HiddenFields";
import { TextField } from "./TextField";
const { Title } = Typography;

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default function FormPlatform({ onSubmit, userEmail, onHomepageClick }) {
  const [isBkActivated, setIsBkActivated] = useState(true);
  const [form] = Form.useForm();

  const currencies = {
    fr: { currency: "EUR", country: "FR" },
    uk: { currency: "GBP", country: "GB" },
  };
  function onSubmitForm(data) {
    data["user.organisation"] = currencies[data["user.organisation"]];
    onSubmit(data);
  }

  function onFormValueChange(valuesUpdated) {
    const valuesChanged = Object.keys(valuesUpdated);
    const value = valuesChanged[0];
    if (value === "config.activateNewBookkeep") {
      setIsBkActivated(valuesUpdated["config.activateNewBookkeep"]);
      form.setFieldsValue({ "config.billing_subscription_plan": undefined });
    }
  }

  return (
    <div style={{ width: "55%", margin: "0 auto" }}>
      <SpendeskButton
        fit="content"
        iconName="caret-left"
        iconPosition="left"
        text="Go back"
        type="button"
        variant="contrasted"
        onClick={() => onHomepageClick(true)}
      />
      <div className="page-content-container">Create a company</div>
      <div className="form-container">
        <Form
          layout="vertical"
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onSubmitForm}
          onValuesChange={onFormValueChange}
          size="medium"
          initialValues={{
            ["config.amount_to_load"]: "100000",
            ["user.organisation.currency"]: "EUR",
            ["config.activateNewBookkeep"]: isBkActivated,
          }}
        >
          {/* HIDDEN FIELDS */}
          <HiddenFields name={"config.amount_to_load"} value={100000} />
          <HiddenFields name={"template"} />
          <HiddenFields name={"config.creator_email"} value={userEmail} />
          {/* END OF HIDDEN FIELDS */}

          <div className="display-flex-space-between">
            <TextField name={"user.organisation.name"} label={"Company Name"} />
            <ChooseLanguage />
          </div>

          <div className="display-flex-space-between">
            <div className="ao-name">
              <TextField name={"user.first_name"} label={"AO's First Name"} />
              <TextField name={"user.last_name"} label={"AO's Last Name"} />
            </div>

            <ChooseCurrency />
          </div>

          <Title level={4}>Billing Subscription Plan</Title>

          <div className="display-flex-space-between">
            <ChooseBillingPlan isBkActivated={isBkActivated} />
          </div>

          <Form.Item {...tailLayout}>
            <Button className="submit-button" type="primary" htmlType="submit">
              Create a company
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
