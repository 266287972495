import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState, createContext } from "react";

const AuthenticatedUserContext = createContext({
  user: undefined,
  userToken: undefined,
});

const AuthenticatedUserProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(null);
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    getIdTokenClaims,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    const updateToken = async () => {
      console.log("Authenticated");
      setUserToken((await getIdTokenClaims()).__raw);
    };
    if (!isLoading && isAuthenticated && !userToken) {
      updateToken();
    }
  }, [getIdTokenClaims, isAuthenticated, isLoading, userToken]);

  if (error) {
    return <div>Error logging in... {error.message}</div>;
  }
  if (isLoading) {
    console.log("Loading authentication state...");
    return null;
  }
  if (!isAuthenticated) {
    console.log("Not authenticated. Redirecting to login page...");
    loginWithRedirect({ connection: "google-oauth2" });
    return null;
  }

  if (!userToken) {
    return null;
  }

  return (
    <AuthenticatedUserContext.Provider value={{ user, userToken }}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};

export { AuthenticatedUserContext, AuthenticatedUserProvider };
