import LoginButton from "./LoginButton";

export default function HeaderProcess({ data, isReseting }) {
  const accountOwner =
    data.Users && data.Users.find(({ role }) => role === "Account owner");
  return (
    <div
      style={{
        width: "100%",
        height: "100px",
        borderRadius: "4px",
        marginBottom: "10px",
        padding: "27px",
        boxShadow:
          "0px 5px 8px -1px rgba(4,2,34,0.12), 0px 2px 4px -1px rgba(4,2,34,0.05)",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div>
          <div className="title-header-process">Name</div>
          <div className="value-header-process">{data.company_name}</div>
        </div>

        <div>
          <div className="title-header-process">Status</div>
          <div className="value-header-process">
            {displayStatus(data, isReseting)}
          </div>
        </div>
        <div>
          <div className="title-header-process">Currency</div>
          <div className="value-header-process">{data.currency}</div>
        </div>
        <div>
          <div className="title-header-process">Plan</div>
          <div className="value-header-process">
            {data.billing_subscription_plan}
          </div>
        </div>
        <div>
          <div className="title-header-process">Backoffice</div>
          <div className="value-header-process">
            {data.company_id ? (
              <a
                href={`https://app.forestadmin.com/Spendesk/Demo/role/data/Company/index/record/Company/${data.company_id}/summary`}
                target="_blank"
                rel="noreferrer"
              >
                Forest link
              </a>
            ) : null}
          </div>
        </div>
        <div>
          <div className="title-header-process"></div>
          <div className="value-header-process">
            <LoginButton user={accountOwner} text={"Log as AO"} />
          </div>
        </div>
      </div>
    </div>
  );
}

function displayStatus(data, isReseting) {
  if (isReseting) {
    return "Reseting data...";
  }
  if (data.status === "success") {
    return "Operational";
  }
  if (data.status === "error") {
    return "Error";
  }
  if (data.status === "pending") {
    return "Creating company...";
  }
}
