import React, { useContext, createContext, useMemo, useState } from "react";
import { debounce } from "lodash";

const entities = {};
const subscribers = [];

const context = createContext();

export function updateEntity(type, id, patch) {
  id = type + "_" + id;
  const patchObj = typeof patch === "function" ? patch(entities[id]) : patch;

  entities[id] = {
    ...entities[id],
    updated_at: new Date().toISOString(),
    ...patchObj,
  };

  subscribers.map((fn) => fn(entities[id]));
}

export function updateEntities(type, list) {
  const ids = list.map((object) => {
    const id = object.id || Date.now() + Math.floor(Math.random() * 999);

    entities[type + "_" + id] = {
      ...entities[type + "_" + id],
      id: type + "_" + id,
      created_at: new Date().toISOString(),
      updated_at: null,
      ...object,
    };
    return id;
  });

  subscribers.map((fn) => fn());

  return ids;
}

export function createEntities(type, list) {
  const ids = list.map((object) => {
    const id = object.id || Date.now() + Math.floor(Math.random() * 999);

    entities[type + "_" + id] = {
      id: type + "_" + id,
      created_at: new Date().toISOString(),
      updated_at: null,
      ...object,
    };
    return id;
  });

  subscribers.map((fn) => fn());

  return ids;
}
export function createEntity(type, object) {
  const id = object.id || Date.now() + Math.floor(Math.random() * 999);

  entities[type + "_" + id] = {
    id: type + "_" + id,
    created_at: new Date().toISOString(),
    updated_at: null,
    ...object,
  };

  subscribers.map((fn) => fn(entities[type + "_" + id]));

  return entities[type + "_" + id];
}

export function EntitiesProvider({ children }) {
  const [defaultValues, updateLocalStorage] = useState(entities);

  const [values, setValues] = useState(entities);
  useMemo(() => {
    Object.assign(entities, defaultValues);
    subscribers.push(
      debounce((object) => {
        setValues({ ...entities });
        updateLocalStorage(entities);
      }, 50)
    );
  }, []);

  useMemo(() => {
    console.count("EntitiesProvider");
  }, [values]);

  // useEffect(() => {
  //     localStorage.getItem("trello.entities", JSON.stringify(entities))
  // }, [])

  return <context.Provider value={values} children={children} />;
}

export function useEntities(type, id) {
  useContext(context);

  if (id && id.splice) {
    return id.map((_id) => entities[type + "_" + _id]).filter((_) => _);
  }

  return entities[type + "_" + id];
}

if (process.env.NODE_ENV === "development") {
  window.entities = entities;
}
