import React from "react";
import { Form } from "antd";
import { TextInput } from "@dev-spendesk/grapes";

export const TextField = ({ name, label }) => {
  return (
    <Form.Item
      style={{ width: "49%", padding: "0 5px 0 0" }}
      name={name}
      label={label}
      required
    >
      <TextInput fit="parent" />
    </Form.Item>
  );
};
