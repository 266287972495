import React from "react";

export default function FlexColumn({ style = {}, ...props }) {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", ...style }}
      {...props}
    />
  );
}
