export default {
  small: {
    block: {
      padding: 10,
      margin: 10,
    },
    inline: {
      padding: 3,
      margin: 3,
    },
    fontSize: 12,
  },
  medium: {
    block: {
      padding: 30,
      margin: 30,
    },
    inline: {
      padding: 10,
      margin: 10,
    },
    fontSize: 16,
  },
  large: {
    block: {
      padding: 50,
      margin: 50,
    },
    inline: {
      padding: 25,
      margin: 35,
    },
    fontSize: 20,
  },
};
