export const authenticatedApi = (authToken) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  const defaultParams = {
    headers,
  };
  const authenticatedFetch = async (url, params = {}) => {
    const response = await fetch(url, { ...defaultParams, ...params });
    return handleResponse(response);
  };

  const get = (originalUrl, queryParams = {}) => {
    const url = new URL(originalUrl);
    Object.keys({ ...queryParams }).forEach((key) =>
      url.searchParams.append(key, queryParams[key])
    );
    return authenticatedFetch(url, { method: "GET" });
  };

  const post = (url, data, params) =>
    authenticatedFetch(url, { method: "POST", body: JSON.stringify(data), ...params });
  const put = (url, data, params) =>
    authenticatedFetch(url, { method: "PUT", body: JSON.stringify(data), ...params });
  const patch = (url, data, params) =>
    authenticatedFetch(url, { method: "PATCH", body: JSON.stringify(data), ...params });
  const del = (url) => authenticatedFetch(url, { method: "DELETE" });

  return {
    get,
    post,
    put,
    del,
    patch,
  };
};

function handleResponse(response) {
  if (response.status >= 400) {
    if (response.status >= 500) {
      return Promise.reject({
        status: response.status,
        errors: [response.statusText],
      });
    }

    console.log("failed to fetch", response);
    return response.json().then((error) =>
      Promise.reject({
        errors: error.errors || error,
        status: response.status,
      })
    );
  }

  return response.json().catch((data) => {
    console.log("couldn't parse json from query");
    return Promise.reject(data);
  });
}
