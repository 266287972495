import React, { createContext, useContext, useMemo } from "react";
import * as palet from "./palet";
import sizes from "./sizes";

const AlertContext = createContext();
const Alert = ({
  children,
  type = "",
  style = {},
  size = "",
  theme = "primary",
}) => {
  if (!theme) theme = type;

  const context = useMemo(() => ({ theme, size }), [theme, size]);
  return (
    <AlertContext.Provider value={context}>
      <div
        className={`theme_${theme} withShadow`}
        style={{
          alignSelf: "center",
          textAlign: "left",
          margin: size === "small" ? 10 : 30,
          padding: size === "small" ? "10px" : "30px",
          borderTop: "5px solid " + palet[theme]._400,
          background: "white",
          color: "black",
          display: "flex",
          alignItems: "center",
          ...style,
        }}
      >
        <div style={{ flex: 1 }}>{children}</div>
      </div>
    </AlertContext.Provider>
  );
};

export default Alert;

export const Title = ({ children, icon = "" }) => {
  const { theme, size } = useContext(AlertContext);

  return (
    <div
      style={{
        margin:
          size === "small"
            ? "-10px -10px 10px -10px"
            : "-30px -30px 30px -30px",
        background: palet[theme]._100,
        color: palet[theme]._900,
        padding: size === "small" ? 10 : 30,
        fontSize: "1.2em",
      }}
    >
      {children}
    </div>
  );
};
export const Footer = ({ children }) => {
  const { size } = useContext(AlertContext);

  const marginSize = sizes[size].block.margin;

  return (
    <div
      style={{
        margin: `${marginSize}px -${marginSize}px -${marginSize}px -${marginSize}px`,
        background: palet.gray._200,
        padding: marginSize,
        borderTop: "1px solid " + palet.gray._400,
      }}
    >
      {children}
    </div>
  );
};

Alert.Title = Title;
Alert.Footer = Footer;
