import React, { useState, useEffect, useContext } from "react";
import Loader from "react-loader-spinner";
import { Spacer } from "../../ui";
import { ENDPOINT } from "./config";
import {
  Button as SpendeskButton,
  Modal as SpendeskModal,
} from "@dev-spendesk/grapes";
import UsersList from "./UsersList";
import HeaderProcess from "./HeaderProcess";

//CSSPROJECT
import { Progress, List, Result } from "antd";

import { CheckCircleTwoTone } from "@ant-design/icons";
import { AuthenticatedApiContext } from "../context/AuthenticatedApiContext";

export default function RenderProcess({
  processId,
  isProcessSelected,
  isResetDataFinished,
  deleteProcess,
}) {
  const [data, setData] = useState();
  const { get } = useContext(AuthenticatedApiContext);

  async function fetch() {
    const data = await get(`${ENDPOINT}/processes/${processId}`);
    data.Tasks.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    setData(data);
  }

  useEffect(() => {
    fetch();
    const interval = setInterval(async () => {
      const data = await get(`${ENDPOINT}/processes/${processId}`);
      data.Tasks.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
      });
      setData(data);
    }, 1000);

    return () => clearInterval(interval);
  }, [processId]);

  if (data)
    return (
      <Process
        data={data}
        deleteProcess={deleteProcess}
        isProcessSelected={isProcessSelected}
        isResetDataFinished={isResetDataFinished}
      />
    );
  return (
    <Loader
      type="Puff"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
      color="rgb(48, 28, 107)"
      height={100}
      width={100}
    />
  );
}

function Process({
  data,
  isProcessSelected,
  isResetDataFinished,
  deleteProcess,
}) {
  const status = getStatus(data);
  return (
    <div>
      <div style={{ width: "70%", margin: "0 auto" }}>
        <div>
          <DisplaySummary
            deleteProcess={deleteProcess}
            isResetDataFinished={isResetDataFinished}
            data={data}
            isProcessSelected={isProcessSelected}
          />
        </div>

        {data.status !== "pending" && data.Users.length ? (
          <div style={{ marginTop: "20px" }}>
            <UsersList users={data.Users} />
          </div>
        ) : status === "error" ? (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, we couldn't find any user. Contact the dev team on #help_demo_account if it's unintended."
          />
        ) : (
          <React.Fragment>
            <Progress
              strokeColor="rgb(48, 28, 107)"
              percent={(data.Tasks.length * 100) / 23}
              showInfo={false}
              status="active"
            />
            <Task data={data.Tasks} />
            <Spacer />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

function DisplaySummary({
  data,
  isProcessSelected,
  isResetDataFinished,
  deleteProcess,
}) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isReseting, setIsReseting] = useState(false);
  const { del } = useContext(AuthenticatedApiContext);

  async function handleRemoveProcess(processId) {
    await deleteProcess(processId);
    setIsDeleteModalOpen(false);
  }

  async function resetCompanyData(processId) {
    try {
      setIsReseting(true);
      await del(`${ENDPOINT}/company/resetData/${processId}`);
      setIsReseting(false);
      isResetDataFinished(true);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <SpendeskButton
          fit="content"
          iconName="caret-left"
          iconPosition="left"
          text="Go back"
          type="button"
          variant="contrasted"
          onClick={() => {
            isProcessSelected(false);
          }}
        />
        <div style={{ display: "flex" }}>
          <SpendeskButton
            fit="content"
            iconName="trash"
            iconPosition="left"
            text="Delete"
            type="button"
            variant="alert"
            onClick={() => setIsDeleteModalOpen(true)}
          />
        </div>
        <SpendeskModal
          isOpen={isDeleteModalOpen}
          actions={[
            <SpendeskButton
              key="no"
              onClick={() => setIsDeleteModalOpen(false)}
              text="No, close this window!"
              variant="secondary"
            />,
            <SpendeskButton
              key="yes"
              text="Yes"
              variant="primary"
              onClick={() => handleRemoveProcess(data.id)}
            />,
          ]}
          iconName="trash"
          title="Are you sure you want to delete this company?"
          variant="primary"
        />
      </div>
      <HeaderProcess data={data} isReseting={isReseting} />
    </React.Fragment>
  );
}

function getStatus(data) {
  if (data.status === "success" || data.status === "error") return "done";
  if (data.status === "pending") return "pending";
  return data.status;
}
function renderStatus(data) {
  if (data.status === "success") {
    return <CheckCircleTwoTone twoToneColor="#52c41a" />;
  }
  if (data.status !== "error" || data.status !== "success") return "⏳";
  const result = data.status === "success" ? "☑️" : "⏳";
  if (data.status === "error") return result + "❌";
  if (data.status === "warning") return result + "⚠️";
}
function Task({ data }) {
  return (
    <List
      bordered
      dataSource={data}
      renderItem={(item) => (
        <List.Item style={{ display: "flex", justifyContent: "flex-start" }}>
          <div style={{ margin: "0 10px" }}>{renderStatus(item)}</div>
          <div>{item.name}</div>
        </List.Item>
      )}
    />
  );
}
