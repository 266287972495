import React, { useContext } from "react";
import { Button as SpendeskButton } from "@dev-spendesk/grapes";
import { ENDPOINT } from "./config";
import { AuthenticatedApiContext } from "../context/AuthenticatedApiContext";

export default function LoginButton({ user, text, processId }) {
  const { get, post } = useContext(AuthenticatedApiContext);

  async function getUserOrAoCredentials(user, processId) {
    if (user && user.email) {
      return { email: user.email, password: user.password };
    }
    if (processId) {
      const users = await get(`${ENDPOINT}/users/${processId}`);
      const accountOwner =
        users.length && users.find((user) => user.role === "Account owner");
      return { email: accountOwner.email, password: accountOwner.password };
    }
  }

  async function login() {
    const userCredentials = await getUserOrAoCredentials(user, processId);
    window.open(
      `https://demo.spendesk.com/authv2/demo/login/cookie?auth_type=password&username=${encodeURIComponent(
        userCredentials.email
      )}&password=${encodeURIComponent(userCredentials.password)}`,
      "_blank"
    );
  }

  const isLoginAvailable = (user && user.email) || processId;

  return (
    <SpendeskButton
      fit="content"
      iconPosition="left"
      iconName="external"
      text={text}
      type="button"
      variant="contrasted"
      onClick={login}
      isDisabled={!isLoginAvailable}
    />
  );
}
