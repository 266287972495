import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import { Auth0Provider } from "@auth0/auth0-react";

import * as serviceWorker from "./serviceWorker";
import { EntitiesProvider } from "./utils/entities";

import "./utils/export-palet-to-scss";
import { AuthenticatedApiProvider } from "./apps/context/AuthenticatedApiContext";
import { AuthenticatedUserProvider } from "./apps/context/AuthenticatedUserContext";

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN}
    clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
    redirectUri={window.location.origin}
    useRefreshTokens={true}
    cacheLocation="localstorage"
    scope="openid profile email"
  >
    <AuthenticatedUserProvider>
      <AuthenticatedApiProvider>
        <EntitiesProvider>
          <App />
        </EntitiesProvider>
      </AuthenticatedApiProvider>
    </AuthenticatedUserProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
