import "../index.css";

import * as _palet from "./palet";
export const themes = [
  "primary",
  "gray",
  "success",
  "danger",
  "warning",
  "accent",
];
export const palet = _palet;
require("../utils/paletCss");
export { default as Alert } from "./Alert";
export { default as Button } from "./Button";
export { default as Icon } from "./Icon";
export { default as Label } from "./Label";
export { default as FlexColumn } from "./FlexColumn";
export { default as FlexRow } from "./FlexRow";
export { default as Spacer } from "./Spacer";
