import React from "react";

export default function Spacer({ size = "" }) {
  return (
    <div
      style={{
        flexBasis: 50,
        flexShrink: 0,
      }}
    />
  );
}
