import React from "react";

import { Form } from "antd";

export const HiddenFields = ({ name, value }) => {
  return (
    <Form.Item
      initialValue={value}
      hidden={true}
      required
      name={name}
    ></Form.Item>
  );
};
