import { List, Typography, Radio, Input } from "antd";
import { useState, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { matchSorter } from "match-sorter";
import {
  Button as SpendeskButton,
  Icon as SpendeskIcon,
  Banner,
} from "@dev-spendesk/grapes";

import LoginButton from "./LoginButton";

const { Title } = Typography;

export default function ListProcess(props) {
  const { processes, userEmail, onProcessSelection, onCreateCompanyClick } =
    props;

  const [isPersonalViewSelected, setPersonalViewSelected] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  function handleViewSelection(event) {
    return setPersonalViewSelected(event.target.value);
  }

  function onRequestAccountClick() {
    const url = new URL(
      "https://spendesk.atlassian.net/servicedesk/customer/portal/2/group/4/create/12"
    );
    url.searchParams.append("summary", "Request Demo Account");
    url.searchParams.append("description", "⚠️ Please specify the plan and the currency for your account ⚠️");
    url.searchParams.append("customfield_10049", "N/A"); // Company ID
    url.searchParams.append("customfield_10050", "N/A"); // Company Name
    url.searchParams.append("customfield_10051", "N/A"); // User Email
    url.searchParams.append("customfield_10243", "10664"); // Environment: Demo

    window.open(url.toString());
  }

  const processSelected =
    isPersonalViewSelected && processes.length
      ? processes.filter((process) => process.creator_email === userEmail)
      : processes;

  const list = useMemo(
    () =>
      matchSorter(processSelected, searchInput, {
        keys: ["company_name", "creator_email"],
      }),
    [processSelected, searchInput]
  );

  list.sort(function (a, b) {
    return new Date(b.created_at) - new Date(a.created_at);
  });


  const allowNewCompany =
  new URLSearchParams(window.location.search).get("allowNewCompany") === "true"
    ? true
    : false;

  return (
    <div style={{ width: "70%", margin: "0 auto" }}>
      <Title style={{ marginBottom: "50px", textAlign: "center" }}>
        Make a demo
      </Title>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        <div style={{ display: "flex" }}>
          <Input
            onChange={(event) => setSearchInput(event.target.value)}
            placeholder="Search by company name or email"
            type="text"
            style={{ width: "300px", marginRight: "10px" }}
          />
          <SpendeskButton
            isDisabled={false}
            fit="content"
            iconName="plus"
            iconPosition="left"
            text="Create a company"
            onClick={() => onCreateCompanyClick(true)}
            type="button"
            variant="primary"
          />
        </div>
        <Radio.Group
          style={{ paddingTop: "15px" }}
          name="radiogroup"
          buttonStyle="solid"
          value={isPersonalViewSelected}
          onChange={handleViewSelection}
        >
          <Radio value={true}>My companies</Radio>
          <Radio value={false}>All</Radio>
        </Radio.Group>
      </div>

      {/*
      <div style={{ margin: "12px 0" }}>
        <Banner
          actionPosition="right"
          actionText="Request an account"
          title="Please raise a request to get a new demo environment"
          variant="warning"
          onClick={onRequestAccountClick}
        >
          New company creation is currently disabled.
        </Banner>
      </div>
      */}

      <div className="demo-infinite-container companies-list">
        <InfiniteScroll initialLoad={false} pageStart={0} useWindow={false}>
          <List
            dataSource={list}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={<DisplayTitle data={item} />}
                  description={<DisplayDescription data={item} />}
                />
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                    <SpendeskButton
                      fit="content"
                      iconPosition="left"
                      text="View"
                      type="button"
                      variant="contrasted"
                      onClick={() => onProcessSelection(item.id)}
                      style={{ marginRight: "10px" }}
                    />
                  </div>

                  <LoginButton processId={item.id} text="Log as AO" />
                </div>
              </List.Item>
            )}
          ></List>
        </InfiniteScroll>
      </div>
    </div>
  );
}

function DisplayDescription({ data }) {
  return (
    <div style={{ display: "flex" }}>
      <div
        className="description-list-processes"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div style={{ marginRight: "7px" }}>
          <SpendeskIcon color="currentColor" name="pen" size="s" />
        </div>
        {data.creator_email}
      </div>
      <div
        className="description-list-processes"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div style={{ marginRight: "7px" }}>
          <SpendeskIcon color="currentColor" name="settings" size="s" />
        </div>
        {data.billing_subscription_plan}
      </div>
    </div>
  );
}

function DisplayTitle({ data }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <Title style={{ marginBottom: "0px" }} level={4}>
        {data.company_name}
      </Title>
      {data.status === "success" ? (
        <IconTitle iconName="success" />
      ) : data.status === "pending" ? (
        <IconTitle iconName="sync" />
      ) : (
        <IconTitle iconName="failure" />
      )}
    </div>
  );
}

function IconTitle({ iconName }) {
  return (
    <div style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
      <SpendeskIcon color="currentColor" name={iconName} size="m" />
    </div>
  );
}
