import { createContext, useContext } from "react";
import { AuthenticatedUserContext } from "./AuthenticatedUserContext";
const { authenticatedApi } = require("../../api/_fetch");

const noop = async () => {
  throw new Error("Unauthenticated");
};

const unauthenticatedApi = {
  get: noop,
  post: noop,
  del: noop,
  patch: noop,
  put: noop,
};

const AuthenticatedApiContext = createContext(unauthenticatedApi);

const AuthenticatedApiProvider = ({ children }) => {
  const { userToken } = useContext(AuthenticatedUserContext);
  return (
    <AuthenticatedApiContext.Provider value={authenticatedApi(userToken)}>
      {children}
    </AuthenticatedApiContext.Provider>
  );
}

export { AuthenticatedApiContext, AuthenticatedApiProvider };
