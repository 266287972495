import * as palet from "../ui/palet";
import { themes } from "../ui";

const base = `
.circle {
    border-radius: 50%;
}
`;

const generated = themes.map(
  (theme) =>
    `.theme_${theme} {
        transition: 0.1s all ease-in-out;
        border-width: 0px;
        border-style: solid;
    }
    .theme_${theme}.withBorder {
        border-width: 1px;
    }
    .theme_${theme}.withBase {
        border-color: ${palet[theme]._500};
        background-color: ${palet[theme]._100};
        color: ${palet[theme]._700};
    }
    .theme_${theme}.withBase.plainStyle {
        background-color: ${palet[theme]._700};
        color: white;
    }
    .theme_${theme}.withShadow {
        box-shadow: 0px 2px 4px hsla(0,0%,0%, .2);
    }
    .theme_${theme}.withHover:hover {
        border-color: ${palet[theme]._700};
    }
    .theme_${theme}.withHover.withShadow:hover {
        box-shadow: 0px 3px 6px hsla(0,0%,0%, .25), 0px 1px 2px hsla(0,0%,0%, .10);
    }
    .theme_${theme}.withHover.withShadow:active, .theme_${theme}.withHover.withShadow:focus {
        outline: none;
    }
    .theme_${theme}.withHover.withShadow:active {
        box-shadow: 1px 1px 3px 1px #00000022;
    }

    .theme_${theme}.disabled, .theme_${theme}:disabled {
        box-shadow: none !important;
        color: ${palet[theme]._500} !important;
        border-color: ${palet[theme]._300} !important;
    }
    `
);

const css = base + generated.join("");
const head = document.head || document.getElementsByTagName("head")[0];
const style = document.createElement("style");

style.type = "text/css";
if (style.styleSheet) {
  // This is required for IE8 and below.
  style.styleSheet.cssText = css;
} else {
  style.appendChild(document.createTextNode(css));
}

head.appendChild(style);
