import React from "react";
import * as palet from "../ui/palet";
import Icon from "./Icon";
import { Link } from "@reach/router";
import "./Button.scss";
import cx from "classnames";

export default function Button({
  style = {},
  active = false,
  disabled = false,
  size = "medium",
  nowrap = false,
  theme = "primary",
  type = "primary",
  className = "",
  variant = "plain",
  icon = "",
  to = "",
  children,
  ...props
}) {
  if (type === "link") {
    console.warn("<Button type='link' -> type='tertiary");
    type = "tertiary";
  }

  const classes = cx(
    "button",
    "clickable",
    "variant-" + variant,
    "theme-" + theme,
    "size-" + size,
    "type-" + type,
    {
      disabled,
      active,
      icon: !!icon,
    }
  );

  const Elm = to
    ? (props) => (
        <Link to={to}>
          <button {...props} />
        </Link>
      )
    : (props) => <button {...props} />;

  return (
    <Elm disabled={disabled} className={classes} style={style} {...props}>
      {icon ? (
        <span className="button-icon">
          <Icon name={icon} />
        </span>
      ) : null}
      <span className="button-content">{children}</span>
    </Elm>
  );
}

Button.ToggleIcon = ({
  onActive,
  name,
  onDisabled,
  active,
  disabled,
  onClick,
  theme = "primary",
}) => {
  return (
    <Icon
      style={{
        background: undefined,
        width: 20,
        height: 20,
        textAlign: "center",
        lineHeight: "18px",
      }}
      name={active ? onActive : disabled ? onDisabled : name}
      className={`clickable theme_${theme} withBase withHover withBorder circle`}
      onClick={onClick}
    />
  );
};

Button.Link = ({ theme = "primary", style = {}, ...props }) => (
  <button
    {...props}
    style={{ color: palet[theme]._700, textDecoration: "underline", ...style }}
  />
);

Button.A = ({ theme = "primary", style = {}, ...props }) => (
  <Link {...props} style={{ color: palet[theme]._700, ...style }} />
);
