import React from "react";
import { Form, Select } from "antd";

const { Option, OptGroup } = Select;

const billingPlans = [
  { key: "freemium", label: "Freemium" },
  { key: "starter", label: "Starter" },
  { key: "essentials", label: "Essentials" },
  { key: "scale", label: "Scale" },
  { key: "enterprise", label: "Enterprise" },
  { key: "premium", label: "Premium" },
];

export const ChooseBillingPlan = ({ isBkActivated }) => {
  return (
    <Form.Item
      style={{ width: "49%", padding: "0 5px 0 0" }}
      name="config.billing_subscription_plan"
      label="Choose your billing subscription plan:"
      rules={[
        {
          required: true,
          message: "Please select your billing subscription plan!",
        },
      ]}
    >
      <Select placeholder="Please select a billing subscription plan">
        {billingPlans.map((plan) => (
          <Option value={plan.key}>{plan.label}</Option>
        ))}
      </Select>
    </Form.Item>
  );
};
