import React from "react";

import { Form, Radio } from "antd";

const currencies = {
  fr: { currency: "EUR", country: "FR" },
  uk: { currency: "GBP", country: "GB" },
};

export const ChooseCurrency = () => {
  return (
    <Form.Item
      style={{ width: "49%", padding: "0 5px 0 0" }}
      name="currency"
      label="Currency"
      rules={[{ required: true, message: "Please pick an item!" }]}
      name="user.organisation.currency"
    >
      <Radio.Group style={{ display: "flex" }} buttonStyle="solid">
        <Radio.Button className="radio-currency" value="EUR">
          Euro (€)
        </Radio.Button>
        <Radio.Button className="radio-currency" value="GBP">
          GBP (£)
        </Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};
